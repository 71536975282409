import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import Vant from 'vant'
import { Toast } from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible'
import axios from 'axios'
import common from './utils/common.js'
import moment from 'moment'
Vue.use(Toast)
Vue.prototype.moment = moment
Vue.prototype.common = common
Vue.prototype.axios = axios
// gyadmin.joinusad.com // 线上
// gyapi.dnopen.com     // 测试服务器
axios.defaults.baseURL = 'https://gyadmin.joinusad.com/api/'
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    if (response.data.code === 401) {
      Toast('登陆失效')
      if (localStorage.getItem('browser') === true && localStorage.getItem('ispc') === 0) {
        router.push({ path: '/login' })
      } else {
        localStorage.removeItem('token')
        location.reload()
      }
    }
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)
Vue.prototype.getQueryValue = function (name) {
  var url = window.location.href
  var result = url.match(new RegExp('[?&]' + name + '=([^&]+)', 'i'))
  if (result == null || result.length < 1) {
    return ''
  }
  return decodeURI(result[1])
}

Vue.use(Vant)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
