// const AppID = "wx3097c317c2a145b0";   // 测试服务器
// const AppID = "wx6da63f7e07f2e207";   // 线上
const AppID = 'wx6da63f7e07f2e207'
export default {
  // 获取微信code
  getCode: function () {
    //snsapi_userinfo snsapi_base
    let REDIRECT_URI = encodeURIComponent(window.location.href)
    // console.log(REDIRECT_URI);
    // return;
    location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + AppID + '&redirect_uri=' + window.location.href + '&response_type=code&scope=snsapi_userinfo&state=123&connect_redirect=1#wechat_redirect'
  },
  // 是否在微信内
  is_weixin() {
    let ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    } else {
      return false
    }
  },
  // 数字专大写
  convertToChineseNumeral(num) {
    if (num == 10) {
      return '十'
    } else if (num == 1) {
      return '一'
    }
    const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    const units = ['', '十', '百', '千', '万']
    let result = ''
    let numStr = num.toString()
    for (let i = 0; i < numStr.length; i++) {
      let digit = parseInt(numStr.charAt(i))
      let unit = units[numStr.length - i - 1]
      if (digit === 0) {
        // 当前数字为0时不需要输出汉字，但需要考虑上一个数字是否为0，避免出现连续的零
        if (result.charAt(result.length - 1) !== '零') {
          result += '零'
        }
      } else {
        result += digits[digit] + unit
      }
    }
    // 对于一些特殊的数字，如10、100等，需要在最前面加上“一”
    if (result.charAt(0) === '一') {
      result = result.substr(1, result.length)
    } else if (result.charAt(0) === '百') {
      result = '一' + result
    } else if (result.charAt(0) === '千') {
      result = '一' + result
    }
    return result
  },
}
